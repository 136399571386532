<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <responsive-form>
        <p style="text-align: center" v-if="!done || total !== 0">保存进度 {{ progress }} {{ error > 0 ? '(错误: ' + error + ')' : '' }}/ {{ total }}</p>
        <p style="text-align: center" v-if="error !== 0">错误卡片 {{ errors.join(', ') }}</p>
        <a-form-item label="Access Code">
          <a-textarea v-model="cards" placeholder="一行一个"></a-textarea>
        </a-form-item>

        <a-form-item label="模板">
          <a-select @change="presetSelect" :value="currentPreset">
            <a-select-option v-for="item in cardPreset" :key="item.name" :value="item.presetId">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </responsive-form>
      <player-card-form
        ref="cardForm"
        :model="formModel"
      />
      <a-button type="primary" @click="handleSubmit">提交</a-button>
    </a-card>
  </page-header-wrapper>
</template>

<script>

import PlayerCardForm from '@/views/game/mai2/module/PlayerCardForm'
import { Mai2CardPreset } from '@/model/mai2const'
import ResponsiveForm from '@/components/ResponsiveForm/ResponsiveForm'
import { savePlayerCard, setPlayerCard } from '@/api/game/mai2'

export default {
  name: 'PlayerDxPass',
  components: {
    ResponsiveForm,
    PlayerCardForm
  },
  data () {
    return {
      cards: '',
      currentPreset: 0,
      cardPreset: Mai2CardPreset,
      confirmLoading: false,
      formModel: null,
      errors: [],
      progress: 0,
      error: 0,
      total: 0,
      done: true
    }
  },
  methods: {
    presetSelect (ev) {
      this.currentPreset = ev
      this.formModel = ev === 0 ? null : Mai2CardPreset[ev]
    },
    checkProgress () {
      if (this.progress === this.total) {
        this.done = true
        if (this.error === 0) {
          this.$message.success('全部卡片保存成功')
        } else {
          this.$message.warn('保存过程中发生了部分错误')
        }
      }
    },
    handleSubmit () {
      this.cards = this.cards.trim()
      if (this.cards === '') {
        this.$message.error('没有填写卡号')
        return
      }
      const cards = this.cards.split('\n')
      // card size check
      if (!cards.every(s => s.length === 20)) {
        this.$message.error('卡号长度必须位20位')
        return
      }
      console.log(cards)

      // check card form
      const form = this.$refs.cardForm.form
      form.validateFields((errors, values) => {
        if (!errors) {
          values.isValid = values.isValid === 'true'
          // Save all value
          this.total = cards.length
          this.errors = []
          this.progress = 0
          this.error = 0
          this.done = false
          // Save every card
          cards.forEach(c => {
            savePlayerCard({ accessCode: c }, values).then(() => {
              setPlayerCard({ accessCode: c, type: values.cardTypeId }).then(() => {
              }).catch(() => {
                this.errors.push(c)
                this.error += 1
              }).finally(() => {
                this.progress += 1
                this.checkProgress()
              })
            }).catch(() => {
              this.errors.push(c)
              this.error += 1
              this.progress += 1
              this.checkProgress()
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
